<script>
	import FlexiPassBox from '~/components/atoms/FlexiPassBox.svelte';
	import SingleRideBox from '~/components/atoms/SingleRideBox.svelte';
	import OfferingSign from '~/icons/offering_sign.svg';
	import OurOfferingDesktopImage from '~/assets/images/OurOfferingDesktop.png?url';
	import { isBusSearch } from './store';
	import DownloadAppButton from '~/components/core/DownloadAppButton.svelte';
	import { isMobile } from '~/utils/store';
	import LiteOffering from '~/components/atoms/LiteOffering.svelte';
	export let downloadAppLink;
</script>

<section class:pt-16={!$isBusSearch} class="pb-14 px-6 md:pt-24 md:pb-28 md:px-36 bg-primary h-fit" id="our-offering">
	<div class="grid grid-cols-1 md:grid-cols-2 items-center h-full">
		<div class="h-full">
			{#if !$isBusSearch || ($isBusSearch && !$isMobile)}
				<div class="text-white pb-11 md:pb-14">
					<h2 class="heading text-start pb-4">Our Offerings</h2>
					<p class="content font-normal">There’s a ride for everyone.</p>
				</div>
			{/if}
			{#if $isMobile && $isBusSearch}
				<div class="text-center pt-6 pb-2">
					<a
						id="download-the-app-search"
						href={downloadAppLink}
						class="inline-block group relative focus:ring-4 w-full md:w-fit focus:outline-none focus:ring-gray-300 nav-out"
						rel="noopener external"
					>
						<DownloadAppButton
							id="download-the-app-footer"
							icons
							{downloadAppLink}
							className="bg-white text-primary h-16"
							iconColor="#00253F"
						/>
					</a>
				</div>
				<div class="md:hidden w-fit mx-auto mt-4 mb-6">
					<p class="text-lg text-white font-bold">
						Your first ride at <span class="text-[#FFD66B]">₹49</span>
					</p>
					<img class="ml-auto -mr-1" src={OfferingSign.src} alt="sign" />
				</div>
			{/if}

			<div class:mb-8={!$isBusSearch} class="flex flex-col gap-y-6 md:gap-y-7 max-w-[50rem] md:mb-16">
				<SingleRideBox />
				<LiteOffering />
				<FlexiPassBox />
			</div>
			{#if !($isMobile && $isBusSearch)}
				<div class="md:w-fit">
					<DownloadAppButton
						id="download-the-app-offering"
						className="bg-white text-primary h-16"
						icons
						rating
						iconColor="#00253F"
						{downloadAppLink}
					/>
				</div>
				<div class="md:hidden w-fit mx-auto mt-8 my-auto">
					<p class="text-lg text-white font-bold">
						Your first ride at <span class="text-[#FFD66B]">₹49</span>
					</p>
					<img class="ml-auto -mr-1" src={OfferingSign.src} alt="sign" />
				</div>
			{/if}
		</div>
		<div class="hidden md:block">
			<img
				class="mx-auto lg:w-[165px] xl:w-[231px] 2xl:w-[308px]"
				src={OurOfferingDesktopImage}
				alt="our offering"
				style="aspect-ratio: 322 /671;"
			/>
			<div class="hidden md:block w-fit mx-auto mt-9">
				<p class="text-2xl text-white font-bold">Your first ride at<span class="text-[#FFD66B]">₹49</span></p>
				<img class="ml-auto" src={OfferingSign.src} alt="sign" />
			</div>
		</div>
	</div>
</section>